.page {
  min-height: 100vh;
  color: white;
}

.text {
  color: white;
}

.alternatives-content-title {
  font-size: 25px;
  padding-bottom: 10px;
  font-weight: 600;
}

.alternatives-row {
  padding-bottom: 100px;
  padding-top: 15px;
}

.alternatives-links {
  color: #9c7506;
}

.alternatives-content-section {
  padding-top: 30px;
}

.alternatives-content {
  padding-bottom: 50px;
  text-align: left;
}

.alternatives-list {
  padding-top: 10px;
  padding-bottom: 10px;
}
