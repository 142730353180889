.App {
  text-align: center;
  /* background-color: #C4C3C2; */
  background-color: #0b0c10;
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-subtitle {
  background-color: #000102;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
