.NavLinks {
  background-color: #1f2833;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: #fbfbf2;
}

.navbar {
  background-color: #1f2833;
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;
}

.navbar-brand {
  color: #9c7506;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #9c7506;
}

.navbar .container {
  margin: 0 !important;
  max-width: 100%;
}
